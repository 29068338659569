/* eslint-disable */
import React from 'react';
import { Button, message, Spin, Modal } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { verifyUser, resendEmail } from '../appRedux/actions/User';
import { connect } from 'react-redux';
import { addCardApi, getCurrentCard, getListPackagesApi, listPaymentCardsApi, subscribeSubscription } from '../appRedux/actions/Payment';
import ListPackages from '../routes/payment/listPackages';
let logoPng = require('assets/images/logo_receipt.png');

class VerifyUser extends React.Component {
  constructor(props) {
    super(props);
    // const query = this.props.location.search;
    const param = this.getQueryParams();
    console.log(`page verify query params= `, param);

    this.state = {
      email: param.email,
      verifyCode: param.token, //token of register
      submitting: false,
      error: null,
      // authToken: '' //token login sau khi verify success
      // listCards: [],
      // currentCard: null,
      // packages: [],
      // packageId: param.packageId, //packageId callback tu payment sau khi add card
      // packageName: param.packageName //packageId callback tu payment sau khi add card
    };
  }
  getQueryParams = () => {
    const query = this.props.location.search;
    const param = queryString.parse(query);
    return param;
  };
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    if (this.props.token) {
      //co token login, user cố ý vào /verify tren browser
    } else {
      if (this.state.verifyCode) {
        //verifyTOken in query token (for register new account)
        this.handleVerify();
      } else {
        this.setState({
          submitting: false,
          error: 'Verify code is missing, please try again'
        });
      }
    }
  }

  // getListPackages = async () => {
  //   try {
  //     let r = await getListPackagesApi();
  //     let pacagkers = r.data && r.data.items ? r.data.items : r.data;
  //     if (r.data) {
  //       this.setState({
  //         packages: pacagkers
  //       });
  //       console.log(`list pacakges`, pacagkers);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     message.error(e);
  //   }
  // };

  // getListPaymentMethodUser = async () => {
  //   try {
  //     let r = await listPaymentCardsApi();
  //     let cards = r.data && r.data.items ? r.data.items : r.data;
  //     if (r.data) {
  //       this.setState({
  //         listCards: cards,
  //         currentCard: getCurrentCard(cards)
  //       });
  //     }
  //     console.log(`list card of user`, cards);
  //   } catch (e) {
  //     console.log(e);
  //     message.error(e);
  //   }
  // };

  handleVerify = async () => {
    this.setState({
      submitting: true
    });
    try {
      //khi verify xong, se nhan dc token tu BE
      //dung token nay get list packages
      let r = await verifyUser({
        token: this.state.verifyCode
      });
      // let authToken = r && r.data && r.data.token ? r.data.token : r.token;
      // if (authToken) {
      //   await this.setStateAsync({
      //     authToken,
      //     submitting: false
      //   });
      //   localStorage.setItem('token', authToken);
      // }
      // await Promise.all([this.getListPaymentMethodUser(), this.getListPackages()]);
      this.setState({
        submitting: false,
        error: null
      });
      message.success('Your account has been verified successfully.');
      Modal.info({
        title: 'Successfully',
        content: 'Your account has been verified successfully. Click to login now!',
        onOk: async () => {
          this.props.history.push('/signin');
          this.setState({
            submitting: false,
            error: null
          });
        },
        onCancel: async () => {
          this.props.history.push('/signin');
          this.setState({
            submitting: false,
            error: null
          });
        }
      });
    } catch (error) {
      console.log(error, JSON.stringify(error));
      this.setState({
        submitting: false,
        error: 'Token is invalid, please try again'
      });
      message.error('Token is invalid, please try again');
    }
  };

  onResendEmail = async () => {
    const { submitting } = this.state;

    if (submitting) {
      return;
    }
    this.setState({
      submitting: true
    });
    try {
      await resendEmail({
        email: this.state.email
      });
      message.success('Email sent successfully');
      this.setState({
        submitting: false,
        error: null,
      });
    } catch (e) {
      message.error('Failed to resend email, with error code: ' + e.message);
      this.setState({
        submitting: false,
        error: 'There are some errors'
      });
    }
  };


  render() {
    const {
      email,
      submitting,
      // authToken,
      // packages, listCards,
      verifyCode
    } = this.state;
    const loggedToken = this.props.token;

    if (loggedToken) {
      //user da login roi
      return <Redirect to={'/dashboard'} />;
    }
    return (
      <div className="gx-login-container fix-height">
        <div className="gx-login-content gx-text-center">
          <div className="gx-login-header">
            <img src={logoPng} style={{ height: '100px' }} alt="alt" />
          </div>
          <div className="gx-mb-4 text-center">
            <h3>Verify your email</h3>

            <div className="row">
              <div className="col-md-12">
                {submitting ? (
                  <div className="w-100 text-center mt-3 mb-3">
                    <Spin></Spin>
                  </div>
                ) : null}
              </div>

              {this.state.error ? (
                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                  <span className="text-danger">{this.state.error}</span>
                </div>
              ) : null}

              <div className="col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                You will need to verify your email to complete registration.
                <a href="/" className="d-block mt-2">
                  Go To Home
                </a>
              </div>
            </div>

            {/* have query email & have error */}
            {email && this.state.error && verifyCode ? (
              <div>
                <div style={{ fontSize: 200 }}>
                  <i className="icon icon-mail-open" />
                </div>
                <p>
                  An email have been sent to <b>{email}</b> with a link to verify your account. If you have not received the email after a few minutes, please check your spam folder.
                </p>
                <Button disabled={submitting} type="primary" htmlType="button" onClick={this.onResendEmail}>
                  Resend Email
                </Button>
                <span className='mr-2 mr-right-5'>
                  <IntlMessages id="app.userAuth.or" />
                </span>
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps)(VerifyUser);
