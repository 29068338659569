/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LocaleProvider, message } from 'antd';
import { IntlProvider } from 'react-intl';

import socketIOClient from 'socket.io-client';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import VerifyUser from '../VerifyUser';
import AppCallbackPayment from '../AppCallbackPayment';
import ForgetPassword from '../../routes/forgetPassword/ForgetPassword';
import { setInitUrl } from 'appRedux/actions/Auth';
import { getAuthUser } from 'appRedux/actions/User';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';
import axios from 'util/Api';
import LoadingOverlay from 'react-loading-overlay';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from '../../constants/ThemeSetting';
import { getUser } from '../../appRedux/actions/Auth';
import { apiUrl, googleApiKey, socketUrl } from '../../util/config';
import ReceiptView from '../ReceiptView';
import CloverOauthCallback from '../CloverOauthCallback';

export const socket = socketIOClient(socketUrl, {
  transports: ['websocket'],
  forceNew: true,
  reconnection: true,
  reconnectionDelay: 5000, // The initial delay before reconnection in milliseconds
  reconnectionDelayMax: 10000, // The maximum delay between two reconnection attempts. Each attempt increases the reconnection delay by 2x.
  reconnectionAttempts: 99999, // The number of reconnection attempts before giving up.
  query:'fromSource=BizmanWeb',
  pingInterval: 10000,
  pingTimeout: 5000,
});
socket.on('pong', function(data) {
  // console.log(`%csocket on [pong] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;', data);
});
socket.on("ping", function(data) {
  // console.log(`%csocket on [ping] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;', data);
});
socket.on('connect_error', () => {
  console.log(`%csocket on [connect_error] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});
socket.on('connect_timeout', () => {
  console.log(`%csocket on [connect_timeout] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});
socket.on('close', () => {
  console.log(`%csocket on [close] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});
socket.on('reconnect', ()=> {
  console.log(`%csocket on [reconnect] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});
socket.on('reconnect_error', ()=> {
  console.log(`%csocket on [reconnect_error] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});
socket.on('reconnecting', ()=> {
  console.log(`%csocket on [reconnecting] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;');
});

const RestrictedRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    token={token}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSocketConnected:false,
      isJoinedRoom: false
    };
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  emitJoinRoom=(tokenn, joinByAction = '')=>{
    let token = String(tokenn).toString();
    if (token && token.indexOf('Bearer') >= 0) {
      token = token.replace('Bearer', '');
      token = token.trim();
    }
    if(token && this.state.isSocketConnected == true && this.state.isJoinedRoom == false){
      if(socket){
        socket.emit('bizmanConnect', {
          token: token,
        });
        this.setState({
          isJoinedRoom:true,
        });
        console.log(`%c socket emit event [bizmanConnect], join room success, ${joinByAction}`, 'background:green;padding:5px;color:white;font-size:20px;', {token});
      } //because socket disconnect & reconnect (socket will out room) => need to emit bizmanConnect to join room again
    }
  }

  componentDidMount() {
    if(this.props.token && this.state.isJoinedRoom == false){
      this.emitJoinRoom(this.props.token, `[join room by componentDidMount]`);
    }

    socket.on('connect', () => {
      this.setState({
        isSocketConnected:true,
      },()=>{
        console.log(`%csocket on [connect] successfully ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;', {'socket.id' : socket.id}); // x8WIv7-mJelg7on_ALbx
        let token = localStorage.getItem('token');
        if(token && this.state.isJoinedRoom == false){
          this.emitJoinRoom(token,`[join room by socket.on['connect']`);
        }
      })
    });
    socket.on('disconnect', (reason) => {
      console.log(`%csocket on [disconnect] ${moment().format("YYYY-MM-DD HH:mm:ss")}`, 'background:green;padding:5px;color:white;font-size:20px;', {'socket.id': socket.id, reason}); // undefined
      this.setState({
        isSocketConnected:false,
        isJoinedRoom:false,
      })
    });
    

    //add gg maps script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,geometry,drawing&key=${googleApiKey}`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      console.log(`%c Google Maps Keys loaded complete`, 'background: green; font-size:14px; padding:10px;color:white;');
    });
    document.body.appendChild(script);


    let checkToken = this.props.token || localStorage.getItem('token');
    if (checkToken) {
      let decoded = jwt_decode(checkToken);
      let dateExp = new Date(decoded.exp * 1000);
      if (moment(dateExp).isBefore(moment())) {
        console.log(`%c expired token, remove localstorage, redirect to login`, 'background: red; font-size:14px; padding:10px;color:white;', decoded);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.clear();
        window.location.href = '/signin';
        return;
      } else {
        // console.log(`%c token still ok, not expire`, 'background: green; font-size:14px; padding:10px;color:white;', decoded);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token && this.props.token !== nextProps.token) {
      axios.defaults.headers.common['authorization'] = nextProps.token;
      if(nextProps.token){
        this.emitJoinRoom(nextProps.token, `[join room by componentWillReceiveProps]`);
      }
    }

    const { errorMessage, successMessage, warningMessage, infoMessage } = nextProps;
    if (nextProps && nextProps.errorMessage && nextProps.errorMessage !== this.props.errorMessage) {
      message.error(errorMessage);
    }
    if (nextProps && nextProps.successMessage && nextProps.successMessage !== this.props.successMessage) {
      message.success(successMessage);
    }
    if (nextProps && nextProps.warningMessage && nextProps.warningMessage !== this.props.warningMessage) {
      message.warn(warningMessage);
    }
    if (nextProps && nextProps.infoMessage && nextProps.infoMessage !== this.props.infoMessage) {
      message.info(infoMessage);
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    let { match, location, themeType, layoutType, navStyle, locale, token, authUser, initURL } = this.props;

    if (!authUser) {
      authUser = getAuthUser();
    }

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }

    if ([null, '', '/', undefined].includes(location.pathname)) {
      if (!token) {
        return <Redirect to={'/signin'} />;
      } else if (['', '/', '/signin', null, undefined].includes(initURL)) {
        return <Redirect to={'/dashboard'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    if (token && location.pathname === '/signup') {
      return <Redirect to={'/dashboard'} />;
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LoadingOverlay active={this.props.loading} spinner={true} text="">
        <LocaleProvider locale={currentAppLocale.antd}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgetPassword} />
              <Route exact path="/verify" component={VerifyUser} />
              <Route exact path="/app/callback-payment" component={AppCallbackPayment} />
              <Route exact path="/site/clover/oauth_callback" component={CloverOauthCallback} />
              <Route exact path="/receipt" component={ReceiptView} />
              <RestrictedRoute path={`${match.url}`} token={token} authUser={authUser} locale={locale} component={MainApp} />
            </Switch>
          </IntlProvider>
        </LocaleProvider>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = ({ settings, auth, commonData }) => {
  let { locale, navStyle, themeType, layoutType } = settings;
  let { authUser, token, initURL } = auth;
  let { errorMessage, successMessage, warningMessage, infoMessage, loading } = commonData;
  if (!token && localStorage.getItem('token')) {
    token = localStorage.getItem('token');
  }
  if (!authUser) {
    authUser = getAuthUser();
  }
  return { locale, token, navStyle, themeType, layoutType, authUser, initURL, loading, errorMessage, successMessage, warningMessage, infoMessage };
};
export default connect(mapStateToProps, { setInitUrl, getUser, setThemeType, onNavStyleChange, onLayoutTypeChange })(App);
