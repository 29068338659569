import axios from 'util/Api';

export const getListOrder = (params, headers = null) => {
  //api/orders/list?limit&offset&status
  let disableloading = headers && headers.disableloading;
  let options = {
    params,
  };
  if(headers && disableloading === true){
    options.headers = {
      disableloading: true,
    }
  }
  return axios.get('api/orders/list', options).then((r) => r.data);
};

export const getOrderDetail = (id) => {
  //api/orders/list?limit&offset&status
  return axios.get('api/orders/' + id).then((r) => r.data);
};

export const rejectOrder = (params) => {
  return axios.put(`api/orders/${params.id}/reject`, params).then((r) => r.data);
};

export const confirmOrder = (params) => {
  return axios.put('api/orders/' + params.id + '/confirm', params).then((r) => r.data);
};

export const notifySlack = (params) => {
  return axios.post('client/notify/slack', params).then((r) => r.data);
};

export const checkUsernameIsUserOrDemo = (user) => {
  if (user) {
    let fullName = user.firstName + user.lastName;
    fullName = fullName || '';
    if (fullName.indexOf('user') >= 0 || fullName.indexOf('demo') >= 0 || fullName.indexOf('nguyentvk') >= 0 || fullName.indexOf('quyench') >= 0) {
      return true;
    }
  }
  return false;
};
