
/* eslint-disable */
if (!process.env.REACT_APP_ENV) {
  process.env.REACT_APP_ENV = 'development';
}
export const isProd = process.env.REACT_APP_ENV === 'production';
let API_URL = isProd ? 'https://api.dikauri.com/' : 'https://tabletopapi.dikauri.com/';
let SOCKET_URL = isProd ? 'wss://api.dikauri.com/' : 'wss://tabletopapi.dikauri.com/';

if (window.location.href.indexOf('localhost') >= 0) {
  // API_URL = 'https://tabletopapi.dikauri.com/';
  // SOCKET_URL = 'wss://tabletopapi.dikauri.com/';
}
console.log(`%c env app started`, 'background:green; font-size:20px; color:white; padding:10px', {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  API_URL: API_URL,
  SOCKET_URL: SOCKET_URL,
});

export const BIZMAN_VERSION = 'v1.0.75';
export const apiUrl = API_URL;
export const footerText = `© Copyright 2020 Digital Kauri. All rights reserved. Version ${BIZMAN_VERSION}`;
export const googleApiKey =  'AIzaSyBuNPW0PjmCo_r6TncEfLR5frWkItj1XeI';
export const maxMenuImage =  5;
export const socketUrl =  SOCKET_URL;
export const CREATIVE_FORM  = 'creativeForm';
export const SLIDER_FORM    = 'sliderForm';
export const LIMIT_SIZE_IMAGE = 1; //1mb
export const LIMIT_SIZE_VIDEO = 10; //10mb
export const PAYMENT_ADD_CARD_REDIRECT_URL = isProd ? "https://app.dikauri.com/payment" : "https://bizman.dikauri.com/payment";
export default {
  footerText: footerText,
  apiUrl: apiUrl,
  socketUrl: socketUrl,
  maxMenuImage: maxMenuImage,
  googleApiKey: googleApiKey,
  BIZMAN_VERSION: BIZMAN_VERSION
};
