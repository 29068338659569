/* eslint-disable */
import './polyfills';


import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import {AppContainer} from 'react-hot-loader';



// Wrap the rendering in a function:
const render = () => {
  let hostname = window.location.host || window.location.hostname;
  if(window.location.protocol !== 'https:' ) {
    if(hostname.indexOf('localhost') === -1){
      // console.log("%c you are accessing us via  an insecure protocol (HTTP). Redirecting you to HTTPS.", 'background:green; color:white; font-size:20px;');
      // console.log(`you are accessing us via  an insecure protocol (HTTP). Redirecting you to HTTPS`);
      //auto force https
      window.location.href = window.location.href.replace("http://", "https://");
      return;
    }else{
      // console.log("%c you are accessing via localhost", 'background:green; color:white; font-size:20px;');
      return ReactDOM.render(
        <AppContainer>
          <NextApp/>
        </AppContainer>,
        document.getElementById('root')
      );
    }
  }else if(window.location.protocol === 'https:'){
    // console.log("%c you are accessing us via our secure HTTPS protocol.", 'background:green; color:white; font-size:20px;');
    return ReactDOM.render(
      <AppContainer>
        <NextApp/>
      </AppContainer>,
      document.getElementById('root')
    );
  }
};

// Do this once
registerServiceWorker();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render();
  });
}
