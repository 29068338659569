/* eslint-disable */
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { Button, Checkbox, Form, Icon, Input, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgetPasswordUser } from '../../appRedux/actions/User';
import * as qs from 'query-string';

const FormItem = Form.Item;

const STEP_FORGET_PASS = 'STEP_FORGET_PASS';
const STEP_RESET_PASS = 'STEP_RESET_PASS';
const STEP_SUCCESS = 'STEP_SUCCESS';
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    const query = qs.parse(this.props.location.search);
    console.log(`query`, query);
    this.state = {
      email: query && query.email ? query.email : '',
      otp: query && query.otp ? query.otp : '',
      expiredTime: query && query.expiredTime ? parseInt(query.expiredTime) : null,
      stepView: STEP_FORGET_PASS,
      password: '',
      rePassword: '',
    };
  }

  componentDidMount() {
    if (this.state.otp && this.state.email) {
      this.setState({
        stepView: STEP_RESET_PASS,
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        if (values.password && values.password !== values.rePassword) {
          message.error('Password doesnt match');
          return;
        }
        if (this.state.stepView === STEP_FORGET_PASS) {
          forgetPasswordUser(values)
            .then(r => {
              console.log(r);
              message.success(`Your request forget password has been sent. Please check email to get otp code !`);
              if (r) {
                this.setState({
                  otp: '',
                  email: values.email,
                  stepView: STEP_RESET_PASS,
                });
              }
            })
            .catch(e => {
              message.error(e);
            });
        } else if (this.state.stepView === STEP_RESET_PASS) {
          forgetPasswordUser(values)
            .then(r => {
              message.success(`Your password has been reset. You can login now!`);
              if (r && r.data) {
                this.setState({
                  stepView: STEP_SUCCESS,
                  otp: '',
                  email: '',
                });
              }
            })
            .catch(e => {
              message.error(e);
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { otp, email, password, rePassword, stepView, expiredTime } = this.state;
    //vi BE chua + them 5phut cho expiredTime => phai + them 5phut vao
    let fiveMinusExpired = 5 * 60 * 1000;
    let newExpiredTime = expiredTime + fiveMinusExpired;
    if (this.state.stepView !== STEP_SUCCESS && typeof expiredTime == 'number' && !isNaN(expiredTime) && (newExpiredTime) < new Date().getTime()) {
      return (
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div>
              <div className="gx-login-container">
                <div className="gx-login-content">
                  <div className="gx-login-header">
                    <img alt="" src={require('assets/images/logo.png')} />
                  </div>
                  <div className="gx-mb-4">
                    <h2>Forgot Your Password ?</h2>
                    <p className="text-danger">Request forget password has been expired.</p>
                    <div>
                      Aready have an account ? <Link to="/signin">Sign In</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.stepView === STEP_SUCCESS) {
      return (
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div>
              <div className="gx-login-container">
                <div className="gx-login-content">
                  <div className="gx-login-header">
                    <img alt="" src={require('assets/images/logo.png')} />
                  </div>
                  <div className="gx-mb-4">
                    <h2>Forgot Your Password ?</h2>
                    <div className="text-primary">Your password has been reset successfully. </div>
                    <div className="mt-3">
                      Please click <Link to="/signin">Sign In</Link> to login now. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div>
            <div className="gx-login-container">
              <div className="gx-login-content">
                <div className="gx-login-header">
                  <img alt="" src={require('assets/images/logo.png')} />
                </div>
                <div className="gx-mb-4">
                  <h2>Forgot Your Password ?</h2>
                  <p>
                    <IntlMessages id="app.userAuth.forgot" />
                  </p>
                </div>
                <Form layout="vertical" onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
                  <FormItem label="Email">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ],
                      initialValue: email,
                      disabled: this.state.stepView === STEP_RESET_PASS,
                    })(<Input type="email" placeholder="E-Mail Address" disabled={this.state.stepView === STEP_RESET_PASS} />)}
                  </FormItem>

                  {stepView === STEP_RESET_PASS ? (
                    <FormItem label="Code" extra={<div className="text-small small text-info">Please check email to get otp code.</div>}>
                      {getFieldDecorator('otp', {
                        rules: [
                          {
                            required: true,
                            message: 'Please check your email and input otp code.',
                          },
                        ],
                        initialValue: otp,
                      })(<Input type="text" placeholder="Code" />)}
                    </FormItem>
                  ) : null}

                  {stepView === STEP_RESET_PASS ? (
                    <FormItem label="Password" title="Password">
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your password',
                          },
                        ],
                        initialValue: password,
                      })(<Input type="password" placeholder="Password" />)}
                    </FormItem>
                  ) : null}

                  {stepView === STEP_RESET_PASS ? (
                    <FormItem label="Re-Password" title="Re-Password">
                      {getFieldDecorator('rePassword', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your re-password',
                          },
                        ],
                        initialValue: rePassword,
                      })(<Input type="password" placeholder="Re-Password" />)}
                    </FormItem>
                  ) : null}
                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Submit
                    </Button>
                    <span>
                      <IntlMessages id="app.userAuth.or" />{' '}
                    </span>
                    <Link to="/signin">
                      <IntlMessages id="app.userAuth.signIn" />
                    </Link>
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);
const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};
export default connect(mapStateToProps, {})(WrappedForgotPasswordForm);
