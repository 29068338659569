/* eslint-disable */
import React from 'react';
import { Button, message, Spin, Modal } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { verifyUser, resendEmail } from '../appRedux/actions/User';
import { connect } from 'react-redux';
import { usersCloverLogin } from '../appRedux/actions/User';
import { userLoginCloverReduxAction } from '../appRedux/actions';
class CloverOauthCallback extends React.PureComponent {
  //React.Component
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      merchantId: '',
      appId: '',
      submitting: false,
      error: ''
    };
  }
  componentDidMount() {
    // ví dụ em callback về trang này nè ?merchant_id={MERCHANT_ID}&client_id={APP_ID}&code={AUTHORIZATION_CODE}
    let query = new URLSearchParams(this.props.location.search);
    let merchantId = query.get('merchant_id');
    let appId = query.get('client_id');
    let code = query.get('code');
    console.log({
      URLSearchParams: query,
      merchantId,
      appId,
      code
    });
    this.setState(
      {
        merchantId,
        appId,
        code
      },
      () => {
        if (merchantId && appId && code) {
          this.handleLoginClover();
        }
      }
    );
  }
  handleLoginClover = async () => {
    const { merchantId, appId, code } = this.state;
    if (this.state.submitting) {
      return;
    }
    this.setState({
      submitting: true
    });
    try {
      let r = await usersCloverLogin({
        merchantId,
        appId,
        authorizationCode: code
      });
      this.setState({
        submitting: false,
        error: ''
      });
      // message.success('Your account has been verified successfully.');
      // Modal.info({
      //   title: 'Successfully',
      //   content: 'Your account has been verified successfully. Click to login now!',
      //   onOk: async () => {
      //     this.props.history.push('/signin');
      //     this.setState({
      //       submitting: false,
      //       error: null
      //     });
      //   },
      //   onCancel: async () => {
      //     this.props.history.push('/signin');
      //     this.setState({
      //       submitting: false,
      //       error: null
      //     });
      //   }
      // });
      this.props.userLoginCloverReduxAction(r, (err, userInfo) => {
        if (userInfo) {
          this.props.history.push('/');
          // window.location.href = '/';
          // window.location.reload();
          return;
        }
      });
    } catch (error) {
      console.log(error, JSON.stringify(error));
      this.setState({
        submitting: false,
        error: JSON.stringify(error)
      });
      message.error(JSON.stringify(error));
    }
    // this.setState({
    //   submitting: false
    // });
  };

  render() {
    const { merchantId, appId, code } = this.state;
    if (!merchantId || !appId || !code) {
      return (
        <div className="w-100 d-block text-center" style={{ position: 'fixed', top: '15%' }}>
          <Spin size={'large'} />
        </div>
      );
    }
    return (
      <div>
        <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content mt-4" style={{padding:'15px'}}>
            {this.state.error ? <div className='text-danger'>
              <h3 className='text-danger'>There are some error. Please try again </h3>
              {this.state.error}
              </div> : null}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, {
  userLoginCloverReduxAction: userLoginCloverReduxAction
})(CloverOauthCallback);
