import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';
// import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions";
import { withRouter } from 'react-router-dom';
import { isProd } from '../../util/config';

class UserInfo extends Component {
  render() {
    const { authUser, userSignOut } = this.props;
    if (!authUser) {
      return null;
    }
    const avatar = this.props.authUser && this.props.authUser.avatar ? this.props.authUser.avatar : 'https://wieldy.g-axon.work/static/media/garry-sobars.aa4d3b15.png';
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li
          onClick={() => {
            this.props.history.push(`/user/me`);
          }}
        >
          <i className="icon icon-user mr-2"></i> My Profile
        </li>

        {isProd ? null : (
          <li
            onClick={() => {
              this.props.history.push(`/payment`);
            }}
          >
            <i className="icon icon-card mr-2"></i>Subscription & Payment
          </li>
        )}

        <li onClick={() => userSignOut()}>
          <i className="icon icon-arrow-right mr-2"></i>
          Logout{' '}
        </li>
      </ul>
    );
    if (this.props.viewIn === 'Sidebar') {
      return (
        <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
          <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
            <Avatar src={avatar} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
            <span className="gx-avatar-name">
              {authUser.firstName} {authUser.lastName}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
          </Popover>
        </div>
      );
    }

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={avatar} className="gx-avatar gx-pointer" alt="" />
      </Popover>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { userSignOut })(withRouter(UserInfo));
