/* eslint-disable */
import React, { Component } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { message, DatePicker, Select, Table, Modal } from 'antd';

import Widget from 'components/Widget/index';
import moment from 'moment';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addCardApi, getListPackagesApi } from '../../appRedux/actions/Payment';
import { PAYMENT_ADD_CARD_REDIRECT_URL } from '../../util/config';

class ListPackages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: props.authUser
    };
  }

  async componentDidMount() {}

  submitBuyPackage = packageInfo => {
    // TEST
    // return this.props.submitAddNewCard(packageInfo);

    if (!this.props.currentCard) {
      if (!Array.isArray(this.props.listCards) || this.props.listCards.length === 0) {
        if (this.props.submitAddNewCard) {
          this.props.submitAddNewCard(packageInfo);
          return;
        }
      }
    }

    if (this.props.submitSubscribePackage) {
      this.props.submitSubscribePackage(packageInfo);
    }
  };

  render() {
    const { packages, subscribedPackage } = this.props;
    const colorIndex = ['#fe397a', '#10bb87', '#5d78ff'];
    const cardClassIndex = ['pricing-plan-basic', 'pricing-card-highlighted pricing-plan-pro', 'pricing-plan-enterprise'];
    const iconIndex = ['icon-apps', 'icon-important', 'icon-setting'];
    return (
      <div>
        <div>
          <h1 className="text-left bold text-bold text-info">PRICING PLAN</h1>
          <div className="row">
            {(packages || []).map((item, idx) => {
              const isSubscribedThisPacakge = subscribedPackage && subscribedPackage.id == item.id;
              const currencyCodePackage = String(item.currency).toUpperCase();
              const pricePackage = item.price;
              return (
                <div className="col-md-4" key={idx}>
                  <div className={`card pricing-card ${cardClassIndex[idx]}`}>
                    <div className="card-body" style={isSubscribedThisPacakge ? { background: '#e9faed' } : {}}>
                      {/* <i className={`icon ${iconIndex[idx]}`} style={{ color: colorIndex[idx] }}></i> */}
                      <p className="pricing-plan-title font-30">{item.packageName}</p>
                      <h3 className="pricing-plan-cost ml-auto">
                        {
                          // new Intl.NumberFormat(
                          //   currencyCodePackage === 'USD' ?
                          //   'en-US' : currencyCodePackage, {
                          //   style: 'currency',
                          //   currency: currencyCodePackage || 'USD',
                          //   minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                          //   maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
                          // }).format(pricePackage)
                        }
                        {pricePackage} <span className='currency-code'>{currencyCodePackage}</span>
                      </h3>
                      <ul className="pricing-plan-features">
                        <li>
                          <i className="icon icon-check text-info mr-2"></i> Max {item.maxSupportedDevices} devices.
                        </li>
                        <li>
                          <i className="icon icon-check text-info mr-2"></i>Max {item.maxRestorSupportedDevices} POS device.
                        </li>
                        <li>
                          <i className="icon icon-check text-info mr-2"></i>Max {item.maxSupportedPrinters} printers.
                        </li>
                        <li>
                          <i className="icon icon-check text-info mr-2"></i>FREE trial {item.numberOfTrialWeeks} weeks.
                        </li>
                      </ul>

                      <ul className="pricing-plan-menu-features">
                        {item.supportedMenu ? (
                          <li>
                            <i className="icon icon-check-circle-o text-primary mr-2"></i>Digital menu
                          </li>
                        ) : null}
                        {item.inhousePromotionSlide ? (
                          <li>
                            <i className="icon icon-check-circle-o text-primary mr-2"></i>Inhouse promotion slides
                          </li>
                        ) : null}
                        {true ? (
                          <li>
                            <i className="icon icon-check-circle-o text-primary mr-2"></i> All future updates
                          </li>
                        ) : null}
                      </ul>
                      {isSubscribedThisPacakge ? (
                        <>
                          <label className="badge badge-success font-20 pd-3">
                            <i className="icon icon-check"></i>
                            Subscribed
                          </label>
                        </>
                      ) : (
                        <button
                          onClick={e => {
                            this.submitBuyPackage(item);
                          }}
                          className="btn pricing-plan-purchase-btn"
                        >
                          Purchase
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {})(withRouter(ListPackages));
