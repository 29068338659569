import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

// import { userSignIn } from '../appRedux/actions/Auth';
// import IntlMessages from 'util/IntlMessages';
// import InfoView from 'components/InfoView';
// import SocialLogin from '../components/Social/SocialLogin';

/*
 * Payment callback will be call this component and pass param to android device
 */

class AppCallBackPayment extends React.PureComponent {
  //React.Component
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      orderId: '',
      error: '',
    };
  }
  componentDidMount() {
    // ví dụ em callback về trang này nè https://manage-dev.dikauri.com/welcome?orderId=2511&status=SUCCESS&error=
    let query = new URLSearchParams(this.props.location.search);
    let status = query.get('status');
    let orderId = query.get('orderId');
    let error = query.get('error');
    orderId = orderId || '';
    error = error || '';
    status = status || '';
    console.log({
      URLSearchParams: query,
      status,
      orderId,
      error,
    });
    this.setState(
      {
        status,
        orderId,
        error,
      },
      () => {
        try {
          if (window.Android) {
            if (window.Android.getPaymentStatus && typeof window.Android.getPaymentStatus === 'function') {
              window.Android.getPaymentStatus(orderId, status, error); // orderId:string, status:string, error:string
            } else {
              alert(`window.Android.getPaymentStatus is not define`);
            }
          } else {
            alert(`window.Android is not define`);
          }
        } catch (e) {
          console.log(`error window.Android.getPaymentStatus`, e);
          alert(`erro window.Android.getPaymentStatus ERROR`, JSON.stringify(e));
        }
      }
    );
  }

  render() {
    if (!this.state.orderId || !this.state.status) {
      return (
        <div className="w-100 d-block text-center" style={{ position: 'fixed', top: '15%' }}>
          <Spin size={'large'} />
        </div>
      );
    }
    return (
      <div>
        {/* <div>Error: {this.state.error}</div>
        <div>Status: {this.state.status}</div>
        <div>orderId: {this.state.orderId}</div> */}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, {})(AppCallBackPayment);
