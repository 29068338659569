/* eslint-disable */
import { NOTIFY_ERROR_MESSAGE, NOTIFY_SUCCESS_MESSAGE, LOADING_START, FETCH_SUCCESS, INIT_URL, LOADING_STOP, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET } from '../../constants/ActionTypes';
import axios from 'util/Api';
import { message } from 'antd';
import { getErrorMessageApi } from '../../util/helper';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = (params, onSuccess, onError) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    axios
      .post('api/users/create', params)
      .then(({ data }) => {
        if (data && data.data) {
          dispatch({ type: FETCH_SUCCESS });
          onSuccess();
        } else {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: data.error });
          onError();
        }
      })
      .catch((error) => {
        dispatch({
          type: NOTIFY_ERROR_MESSAGE,
          payload: error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message ? error.response.data.errors.message : error.message
        });
        onError();
      });
  };
};

export const socialLogin = (params, onError) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    axios
      .post('api/users/social-login', params)
      .then(({ data }) => {
        if (data && data.data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data.data && data.data.token) {
            setToken(dispatch, data);
          } else {
            onError();
          }
        } else {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: data.error });
          onError();
        }
      })
      .catch((error) => {
        dispatch({
          type: NOTIFY_ERROR_MESSAGE,
          payload: error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message ? error.response.data.errors.message : error.message
        });
        onError();
      });
  };
};

const setToken = (dispatch, data) => {
  let accessToken = data.data.token;
  accessToken = `Bearer ${accessToken}`;
  data.data = {
    ...data.data,
    objectId: data.data.objectId || data.data.id,
    id: data.data.objectId || data.data.id
  };

  if (accessToken) {
    axios.defaults.headers.common['authorization'] = accessToken;
    dispatch({ type: USER_TOKEN_SET, payload: accessToken });
    dispatch({ type: USER_DATA, payload: data.data });
    localStorage.setItem('token', accessToken);
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...data.data
      })
    );
  }
};

const setTokenAndUserForCloverLogin = (dispatch, token, user)=>{
  let accessToken = token;
  accessToken = `Bearer ${accessToken}`;
  let userId = user.objectId || user.id;
  let userInfo = {
    ...user,
    objectId: userId,
    id: userId
  };
  if (accessToken) {
    axios.defaults.headers.common['authorization'] = accessToken;
    dispatch({ type: USER_TOKEN_SET, payload: accessToken });
    dispatch({ type: USER_DATA, payload: userInfo });
    localStorage.setItem('token', accessToken);
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...userInfo
      })
    );
  }
}

export const updateUserInfoReduxState = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_DATA, payload: data });
    localStorage.setItem('user', JSON.stringify(data));
  };
};

export const userSignIn = (obj, callback) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    axios
      .post('api/users/login', obj)
      .then(({ data }) => {
        if (data.data && data.data.token) {
          dispatch({ type: NOTIFY_SUCCESS_MESSAGE, payload: `Login successfully` });
          setToken(dispatch, data);
        } else if (data.data) {
          if (data.data.info && !data.data.info.emailVerified) {
            dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Your account haven't active yet.` });
            callback(data.data);
          } else if (!data.data.token) {
            dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Login fail. Not found token` });
          }
        } else {
          dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `There are something error. Please try again.` });
        }
      })
      .catch(function (error) {
        dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: getErrorMessageApi(error) });
      });
  };
};

export const userLoginCloverReduxAction = (response, callback) => {
  return (dispatch) => {
    let resultLoginClover = response && response.data ? response.data : response;
    if(!resultLoginClover){
      dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `There are something error. Please try again.` });
      return;
    }
    let accessToken = resultLoginClover.token;
    dispatch({ type: LOADING_START });
    try {
      if(accessToken){
        dispatch({ type: NOTIFY_SUCCESS_MESSAGE, payload: `Login successfully` });
        setTokenAndUserForCloverLogin(dispatch, accessToken,  resultLoginClover);
        if(callback) callback(null, resultLoginClover);
      } else if(!accessToken){
        dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: `Login fail. Not found token` });
      }
    } catch (error) {
      dispatch({ type: NOTIFY_ERROR_MESSAGE, payload: getErrorMessageApi(error) });
    }
  };
};

// export const userSignIn = ({email, password}) => {
//   return (dispatch) => {
//     const accessToken = "UkVEQUNURUQ=";
//     // dispatch({type: LOADING_START});
//     // dispatch({type: NOTIFY_ERROR_MESSAGE, payload: data.error});
//     dispatch({type: FETCH_SUCCESS});
//     dispatch({type: USER_TOKEN_SET, payload: accessToken});
//     localStorage.setItem('token', accessToken)
//   }
// };

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_SUCCESS });
    const authUser = JSON.parse(localStorage.getItem('user'));
    dispatch({ type: USER_DATA, payload: authUser });

    // dispatch({type: LOADING_START});
    // axios.post('auth/me',
    // ).then(({data}) => {
    //   console.log("userSignIn: ", data);
    //   if (data.result) {
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: USER_DATA, payload: data.user});
    //   } else {
    //     dispatch({type: NOTIFY_ERROR_MESSAGE, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: NOTIFY_ERROR_MESSAGE, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    window.location.href = '/signin';
  };
};
