export const USER_ROLES = {
  superAdmin: 'SUPER_ADMIN',
  admin: 'ADMIN',
  user: 'USER',
};

export const USER_TYPES = [
  {
    name: 'Customer',
    value: 3,
  },
  {
    name: 'Sale',
    value: 2,
    roles: [USER_ROLES.superAdmin, USER_ROLES.admin]
  },
  {
    name: 'Staff',
    value: 1,
    roles: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.user]
  },
];
