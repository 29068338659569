/* eslint-disable */
import axios from 'util/Api';
import * as moment from 'moment';
import { getErrorMessageApi } from '../../util/helper';

export const getCurrentCard = cards => {
  if (Array.isArray(cards) && cards.length == 1) {
    //only have one card
    return cards[0];
  }
  return (
    (cards || []).find(r => r.isDefault == true) || (Array.isArray(cards) && cards[0] ? cards[0] : undefined) //fallback to card[0]
  );
};

export const getListPackagesApi = () => {
  let testFakeIp = localStorage.getItem('bizman-fake-ip');
  let url = `api/payments/packages${testFakeIp ? `?ip=${testFakeIp}` : ''}`;
  return axios
    .get(url, {
      headers: {
        disableloading: true
      }
    })
    .then(r => r.data)
    .catch(e => {
      console.log(e);
      throw getErrorMessageApi(e);
    });
};

export const getCurrentPackageOfUser = async () => {
  //subscribedPackage, requiredSubscription, expiredTime
  return axios
    .get('api/users/detail', {
      headers: {
        disableloading: true
      }
    })
    .then(r => r.data);
};
export const getCurrentSubscription = async () => {
  return getCurrentPackageOfUser();
};

export const addCardApi = async ({ redirectUrl }) => {
  return axios
    .post(
      'api/payments/add-card',
      {
        redirectUrl
      },
      {
        headers: {
          disableloading: true
        }
      }
    )
    .then(r => r.data);
};

export const removeCardApi = async ({ paymentMethodId }) => {
  if (!paymentMethodId) {
    throw new Error(`removeCardApi paymentMethodId is missing`);
  }
  return axios
    .post(
      'api/payments/remove-card',
      {
        paymentMethodId
      },
      {
        headers: {
          disableloading: true
        }
      }
    )
    .then(r => r.data);
};

export const setCardDefaultApi = async ({ paymentMethodId }) => {
  if (!paymentMethodId) {
    throw new Error(`setCardDefaultApi paymentMethodId is missing`);
  }
  return axios
    .post(
      'api/payments/set-default-card',
      {
        paymentMethodId
      },
      {
        headers: {
          disableloading: true
        }
      }
    )
    .then(r => r.data);
};

export const listPaymentCardsApi = async () => {
  return axios
    .get('api/payments/payment-methods', {
      headers: {
        disableloading: true
      }
    })
    .then(r => r.data);
};

export const listTransactionApi = async () => {
  return axios
    .get('api/payments/transactions', {
      headers: {
        disableloading: true
      }
    })
    .then(r => r.data);
};

export const subscribeSubscription = async ({ paymentPackageId }) => {
  if (!paymentPackageId) throw new Error(`missing paymentPackageId`);
  return axios
    .post(
      'api/payments/subscribe',
      {
        paymentPackageId,
        packageId: paymentPackageId
      },
      {
        headers: {
          disableloading: true
        }
      }
    )
    .then(r => r && r.data)
    .catch(e => {
      console.log(e);
      throw getErrorMessageApi(e);
    });
};

export const deleteCurrentSubscription = async ({ paymentPackageId }) => {
  if (!paymentPackageId) throw new Error(`missing paymentPackageId`);
  return axios
    .delete(`api/payments/subscribe`, {
      data: {
        paymentPackageId,
        packageId: paymentPackageId
      },
      headers: {
        disableloading: true
      }
    })
    .then(r => r && r.data);
};
