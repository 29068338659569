/* eslint-disable */
import { message, Spin } from 'antd';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';
import { getCurrentPackageOfUser } from '../appRedux/actions/Payment';
import { formatExpiredTime } from './../util/helper';
import { USER_ROLES } from '../constants/Roles';

export class AuthoriedApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectToPayment: null
    };
  }

  async componentDidMount() {
    try {
      let r = await getCurrentPackageOfUser();
      if (r && r.data) {
        let responseData = r.data.data || r.data;
        const { subscribedPackage, requiredSubscription, expiredTime, role } = responseData;
        console.info(`[AuthoriedApp] AuthoriedApp currentSub of user  `, responseData);
        //nó có field requiredSubscription đó, cái đó = true thì mới apply cái vụ package cho nó thôi
        //mình vẫn còn mớ khách hàng với shop bên table top mà
        if(role && typeof role == 'object' && role.role == USER_ROLES.admin){
          //admin k check redirect
          this.setState({
            isRedirectToPayment: false
          });
        } else if (requiredSubscription === true  && !subscribedPackage) {
          this.setState({
            isRedirectToPayment: true
          });
        }else{
          this.setState({
            isRedirectToPayment: false
          });
        }
      }
    } catch (e) {
      console.error(e);
      message.error(e);
      this.setState({
        isRedirectToPayment: false
      });
    }
  }

  render() {
    const { match, router, location } = this.props;
    const { isRedirectToPayment } = this.state;

    if (isRedirectToPayment === true && String(location.pathname).startsWith('/payment') === false) {
      console.info(`\n\n\n [AuthoriedApp] User not have subscription, redirect to payment view: `, { router, isRedirectToPayment, match, location });
      return (
        <Redirect
          to={{
            pathname: '/payment',
            state: { from: this.props.location }
          }}
        />
      );
    }
    if(String(location.pathname).startsWith('/payment') === true){
      if(isRedirectToPayment === null){
        return <div className='row'>
          <div className='col-md-12 text-center'>
            <Spin size="large" />
          </div>
        </div>
      }
    }
   
    return (
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route path={`${match.url}sample`} component={asyncComponent(() => import('./404'))} />
          <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./dashboard'))} />
          {/*<Route path={`${match.url}agency`} component={asyncComponent(() => import('./agency'))}/>*/}
          <Route path={`${match.url}creative`} component={asyncComponent(() => import('./creative'))} />
          {/*<Route path={`${match.url}device/group`} component={asyncComponent(() => import('./location'))}/>*/}
          <Route path={`${match.url}device`} component={asyncComponent(() => import('./device'))} />
          <Route path={`${match.url}slider`} component={asyncComponent(() => import('./slider'))} />
          <Route path={`${match.url}user`} component={asyncComponent(() => import('./user'))} />
          <Route path={`${match.url}call-service`} component={asyncComponent(() => import('./callService'))} />
          <Route path={`${match.url}menu`} component={asyncComponent(() => import('./menu'))} />
          <Route path={`${match.url}report`} component={asyncComponent(() => import('./report'))} />
          <Route path={`${match.url}payment`} component={asyncComponent(() => import('./payment/index'))} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AuthoriedApp);
