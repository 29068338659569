/* eslint-disable */
import React, { Component } from 'react';
import { Layout, Popover, message } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import languageData from './languageData';
import { switchLanguage, toggleCollapsedSideNav } from '../../appRedux/actions/Setting';
// import SearchBox from "components/SearchBox";
import UserInfo from 'components/UserInfo';
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
import Auxiliary from 'util/Auxiliary';
import moment from 'moment';
import { socket } from '../../containers/App';
import { getListOrder } from '../../appRedux/actions/Order';

import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import { connect } from 'react-redux';
import { BIZMAN_VERSION, isProd } from '../../util/config';
import { getCurrentPackageOfUser } from '../../appRedux/actions/Payment';
import { USER_ROLES } from '../../constants/Roles';
import { formatExpiredTime } from '../../util/helper';
// const formatExpiredTime = v => {
//   return v && v.iso ? v.iso : v;
// };
const { Header } = Layout;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      totalNewOrder: 0,
      isTrial: false,
      trialExpiredTime: null
    };
  }

  componentDidMount() {
    this.getTotalNeworder();
    this.getCurrentPackage();
    socket.on('newOrder', this.handleNewOrder);
    socket.on('orderConfirmed', this.handleOrderConfirm);
    socket.on('orderRejected', this.handleOrderReject);
  }

  handleNewOrder = msg => {
    console.log('%csocket on newOrder [menu Topbar]', 'background:green; color:white; font-size:20px;', msg);
    this.getTotalNeworder();
  };
  // handleTotalUpdate = (msg)=>{
  //   console.log('%csocket on totalUpdate [menu Topbar]','background:green; color:white; font-size:20px;', msg);
  //   this.getTotalNeworder();
  // }
  handleOrderConfirm = msg => {
    console.log('%csocket on orderConfirmed [menu Topbar]', 'background:green; color:white; font-size:20px;', msg);
    this.getTotalNeworder();
  };
  handleOrderReject = msg => {
    console.log('%csocket on orderRejected [menu Topbar]', 'background:green; color:white; font-size:20px;', msg);
    this.getTotalNeworder();
  };

  componentWillUnmount() {
    // Pass event and fn to remove a listener.
    // Pass event to remove all listeners on that event.
    // Pass nothing to remove all listeners on all events.
    socket.off('newOrder', this.handleNewOrder);
    socket.off('orderConfirmed', this.handleOrderConfirm);
    socket.off('orderRejected', this.handleOrderReject);
  }

  getTotalNeworder = async () => {
    // const {authUser} = this.props;
    try {
      let params = {
        ownerId: this.props.authUser && this.props.authUser.id,
        page: 0,
        limit: 1000,
        offset: 0,
        resolved: false,
        status: 'new'
      };
      let r = await getListOrder(params);
      // console.log(`[menu Topbar] top bar get list new order`, r);
      if (r && r.data) {
        this.setState({
          totalNewOrder: r.data.total
        });
      }
    } catch (e) {
      message.error(e);
    }
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={e => this.props.switchLanguage(language)}>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  getCurrentPackage = async () => {
    try {
      let r = await getCurrentPackageOfUser();
      if (r && r.data) {
        const { subscribedPackage, requiredSubscription, expiredTime, role } = r.data.data || r.data;
        const isTrial = requiredSubscription == true && subscribedPackage && subscribedPackage.isTrial === true;
        if (role && role.role == USER_ROLES.user && isTrial) {
          this.setState({
            isTrial,
            trialExpiredTime: isTrial ? formatExpiredTime(expiredTime || new Date()) : null
          });
        }
      }
    } catch (e) {
      console.log(e);
      message.error(JSON.stringify(e));
    }
  };

  render() {
    const { width, navCollapsed, navStyle } = this.props;
    let { totalNewOrder, isTrial, trialExpiredTime } = this.state;
    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div>
          ) : null}
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="alt" src={require('assets/images/w-logo.png')} />
          </Link>

          {isProd ? null : isTrial ? (
            <div className="trial-notification d-md-block d-lg-block d-sm-none d-xs-none ">
              <span className="text-danger text-bold ml-2 mr-2">
                Your Trial will be expired at {moment(trialExpiredTime).format('DD-MM-YYYY')}.
              </span>
              Click
              <Link to="/payment" className="gx-pointer text-primary text-bold ml-2 mr-2">
                HERE
              </Link>
              to upgrade your plan
            </div>
          ) : null}

          {/*<SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"*/}
          {/*           placeholder="Search in app..."*/}
          {/*           onChange={this.updateSearchChatUser.bind(this)}*/}
          {/*           value={this.state.searchText}/>*/}
          <ul className="gx-header-notifications gx-ml-auto">
            {/*<li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">*/}
            {/*  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={*/}
            {/*    <SearchBox styleName="gx-popover-search-bar"*/}
            {/*               placeholder="Search in app..."*/}
            {/*               onChange={this.updateSearchChatUser.bind(this)}*/}
            {/*               value={this.state.searchText}/>*/}
            {/*  } trigger="click">*/}
            {/*    <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>*/}
            {/*  </Popover>*/}
            {/*</li>*/}
            {/*{width >= TAB_SIZE ? null :*/}
            {/*  <Auxiliary>*/}
            {/*    <li className="gx-notify">*/}
            {/*      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}*/}
            {/*               trigger="click">*/}
            {/*        <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>*/}
            {/*      </Popover>*/}
            {/*    </li>*/}

            {/*    <li className="gx-msg">*/}
            {/*      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
            {/*               content={<MailNotification/>} trigger="click">*/}
            {/*      <span className="gx-pointer gx-status-pos gx-d-block">*/}
            {/*        <i className="icon icon-chat-new"/>*/}
            {/*        <span className="gx-status gx-status-rtl gx-small gx-orange"/>*/}
            {/*      </span>*/}
            {/*      </Popover>*/}
            {/*    </li>*/}
            {/*  </Auxiliary>*/}
            {/*}*/}
            {/*<li className="gx-language">*/}
            {/*<Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()}*/}
            {/*trigger="click">*/}
            {/*<span className="gx-pointer gx-flex-row gx-align-items-center">*/}
            {/*<i className={`flag flag-24 flag-${locale.icon}`}/>*/}
            {/*<span className="gx-pl-2 gx-language-name">{locale.name}</span>*/}
            {/*<i className="icon icon-chevron-down gx-pl-2"/>*/}
            {/*</span>*/}
            {/*</Popover>*/}
            {/*</li>*/}

            {/* {width >= TAB_SIZE ? null :
              <Auxiliary>
                <li className="gx-user-nav"><UserInfo/></li>
              </Auxiliary>
            } */}
            <li className="gx-user-nav container-topbar">{BIZMAN_VERSION}</li>
            {totalNewOrder > 0 ? (
              <li className="gx-msg">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={
                    <Auxiliary>
                      <div className="gx-popover-header">
                        <h3 className="gx-mb-0">Messages</h3>
                        <i className="gx-icon-btn icon icon-charvlet-down" />
                      </div>
                      <CustomScrollbars className="gx-popover-scroll">
                        <ul className="gx-sub-popover">
                          <li className="gx-media">
                            <div className="gx-user-thumb gx-mr-3">
                              <i className="icon icon-orders" style={{ fontSize: '35px' }} />
                              <span className="gx-badge gx-badge-danger gx-text-white gx-rounded-circle">
                                {totalNewOrder > 9 ? '9+' : totalNewOrder}
                              </span>
                            </div>
                            <div className="gx-media-body">
                              <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
                                <h5 className="gx-text-capitalize gx-user-name gx-mb-0">
                                  <span className="gx-link">
                                    <Link to="/dashboard">You have {totalNewOrder} new orders</Link>
                                  </span>
                                </h5>
                                <span className="gx-meta-date">
                                  <small>{moment().format('HH:mm')}</small>
                                </span>
                              </div>
                              <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted">
                                <Link to="/dashboard" className="text-black gx-text-black">
                                  <i className="icon icon-eye gx-pr-2" /> View Detail
                                </Link>
                              </span>
                              {/* <span className="gx-btn gx-btn-sm gx-top2 gx-text-muted"><i
                              className="icon icon-custom-view gx-pr-2" />Read</span> */}
                            </div>
                          </li>
                        </ul>
                      </CustomScrollbars>
                    </Auxiliary>
                  }
                  trigger="click"
                >
                  <span className="gx-pointer gx-status-pos gx-d-block"></span>
                  <span style={{ display: 'inline-block', position: 'relative' }}>
                    <i className="icon icon-notification" />
                    <label
                      style={{ position: 'absolute', top: '-12px', right: '-18px', borderRadius: '50%' }}
                      className="badge badge-danger"
                    >
                      {totalNewOrder > 9 ? '9+' : totalNewOrder}
                    </label>
                  </span>

                  <span className="gx-status gx-status-rtl gx-small gx-orange"> </span>
                </Popover>
              </li>
            ) : null}

            <li className="gx-user-nav container-topbar">
              <UserInfo />
            </li>
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  const { authUser, token } = auth;
  return { locale, navStyle, navCollapsed, width, authUser, token };
};

export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(Topbar);
