import axios from "util/Api";
import * as moment from "moment";

export const getReceiptDetail = id => {
  if (!id) {
    throw new Error(`Missing receipt id`);
  }
  return axios.get("api/orders/receipt/" + id).then(r => r.data);
};

export const downloadReceiptDetail = id => {
  if (!id) {
    throw new Error(`Missing receipt id`);
  }
  return axios
    .get(`api/orders/receipt/download/${id}`, {
      responseType: "blob",
    })
    .then(r => {
      let data = r.data;
      if (!data) {
        console.log(`response download Data empty`);
        throw new Error(`Data empty`);
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.style.display = "none";
      link.setAttribute("download", `receipt-${id}-${moment().format("DD-MM-YYYY")}.pdf`); //or any other extension

      if (typeof link.download === "undefined") {
        link.setAttribute("target", "_blank");
      }

      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        try {
          document.body.removeChild(link);
        } catch (e) {
          console.log(`error e`, e);
        }
      }, 1000);
    }).catch(e=>{
      console.log(e);
      throw e;
    });
};
