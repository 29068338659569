/* eslint-disable */
import ALL_COUNTRY from './countries';
const helper = {
  getErrorMessageApi: (error) => {
    if (error && error.response && error.response.data && error.response.data.errors) {
      if (error.response.data.errors.message) {
        return error.response.data.errors.message;
      }
      return error.response.data.errors;
    }
    // if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.message) return error.response.data.errors.message;
    // if (error && error.response && error.response.data && error.response.data.errors) return error.response.data.errors;
    if (error && error.response && error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message;
      }
      return error.response.data;
    }
    if (error && error.response) {
      if (error.response.message) return error.response.message;
      return error.response;
    }
    if (error && error.message) return error.message;
    // if (error && error.response && error.response.data) return error.response.data;
    // if (error && error.response && error.response.data) return error.response.data;
    return error;
  },
  randomString: (n = 10) => {
    function makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
    return makeid(n);
  },
  isLocalhost: () => {
    let { location } = window;
    return location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '';
  },
  tryPlaySoundAndroidBizman: (fileName, cb = null) => {
    if (!fileName) {
      alert(`fileName is empty, can not play sound`);
      return;
    }
    try {
      if (window && window.handlerPlaySound && window.handlerPlaySound.performClick) {
        if (fileName) {
          window.handlerPlaySound.performClick(fileName);
          if(cb) cb(null, `SUCCESS call android bizman playsound ${fileName}`);
        }
      } else {
        if(cb) cb(`WARN tryPlaySoundAndroidBizman ${fileName}, window.handlerPlaySound.performClick is not define`, null);
      }
    } catch (e) {
      console.error(`ERROR tryPlaySoundAndroidBizman e=`, e);
      let errMessage = (e && e.message) || JSON.stringify(e);
      if(cb) cb(`ERROR tryPlaySoundAndroidBizman ${fileName},, call window.handlerPlaySound.performClick error = ${errMessage}`, null);
    }
  },
  timeoutPromise: (n = 1)=>{
    return new Promise((resolve, rej)=>{
      setTimeout(()=>{
        resolve(null);
      }, n * 1000);
    })
  }
};

export const getAllCountries = () => {
  return ALL_COUNTRY;
};
export const getErrorMessageApi = helper.getErrorMessageApi;
export const randomString = helper.randomString;
export const isLocalhost = helper.isLocalhost;
export const tryPlaySoundAndroidBizman = helper.tryPlaySoundAndroidBizman;
export const timeoutPromise = helper.timeoutPromise;
export const formatExpiredTime = v => {
  return v && v.iso ? v.iso : v;
};
export default helper;
