import React from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
// import InfoView from "components/InfoView";
// import SocialLogin from "../components/Social/SocialLogin";
import {BIZMAN_VERSION} from "../util/config";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values, (user) => {
          if(!user.info.emailVerified) {
            message.warning(`Your account haven't active yet.`);
            this.props.history.push(`/verify?email=${user.info.email}`);
            return;
          }
          this.props.history.push('/');
          // window.location.href = '/';
          // window.location.reload();
          return;
        });
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/*<img src="https://via.placeholder.com/272x395" alt='Neature'/>*/}
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo-h.png")}/>
                <div className="row text-center mt-3">
                  <div className="col-12">
                    {BIZMAN_VERSION}
                  </div>
                </div>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('username', {
                    initialValue: window.location.href.indexOf('localhost')>=0 ? 'nguyentvk@gmail.com' : "",
                    rules: [{
                      required: true,
                    }],
                  })(
                    <Input placeholder="Username"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: window.location.href.indexOf('localhost')>=0 ? 'nguyentvk' : "" ,
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                <Link to="/forgot-password">Forgot your password?</Link>
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <a href="https://dikauri.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank"><span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition"/></span></a>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <span className="mr-2"><IntlMessages id="app.userAuth.or"/></span>
                  <Link to="/signup"><IntlMessages id="app.userAuth.signUp"/></Link>
                </FormItem>
              </Form>

              {/* hide  login  fb  gg  */}
              {/* <SocialLogin /> */}

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
