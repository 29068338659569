import React from 'react';
import { Button, Checkbox, Form, Input, message, Icon, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignUp, socialLogin } from '../appRedux/actions/Auth';
import { registerNewUser } from '../appRedux/actions/User';

import IntlMessages from 'util/IntlMessages';
import { USER_ROLES } from '../constants/Roles';

const FormItem = Form.Item;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      passwordStrength: '',
      showPlainPassword: false
    };
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { submitting } = this.state;
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (submitting) {
        return;
      }
      await this.setStateAsync({
        submitting: true
      });
      try {
        let resultRegister = await registerNewUser({
          ...values,
          username: values.email,
          role: USER_ROLES.user
        });
        console.log(resultRegister);
        // message.info(`Your register request has been sent successfully. Please check your mailbox and verify account.`);
        Modal.info({
          title: 'Successfully',
          content: 'Your register request has been sent successfully. Please check your mailbox and verify account.',
          onOk: async () => {
            await this.setStateAsync({
              submitting: false
            });
            this.props.form.resetFields();
          },
          onCancel: async () => {
            await this.setStateAsync({
              submitting: false
            });
            this.props.form.resetFields();
          }
        });
        // this.props.history.push({
        //   pathname: '/verify',
        //   search: `?email=${values.email}`
        // });
      } catch (error) {
        message.error(JSON.stringify(error));
        console.log(error);
      }
      await this.setStateAsync({
        submitting: false
      });
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }
  comparePasswordCheck = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value.length < 6) {
      return callback(`Confirm password must be minimum 6 characters.`);
    }
    if (value && value !== form.getFieldValue('password')) {
      return callback(`Confirm password doesn't match`);
    }
    return callback();
  };
  strongPasswordCheck = (rule, value, callback) => {
    // const form = this.props.form;
    let textErr = [];
    if (value && value.length < 6) {
      return callback(`Password must be minimum 6 characters.`);
    }
    if (value && /[A-Z]{1,}/.test(value) === false) {
      textErr.push('at least one Uppercase letter');
      // return callback('at least one Uppercase letter');
    }
    if (value && /[a-z]{1,}/.test(value) === false) {
      // return callback('Must contain at least one Lowercase letter');
      textErr.push('at least one Lowercase letter');
    }
    if (value && /[0-9]{1,}/.test(value) === false) {
      // return callback('Must contain at least one number');
      textErr.push('at least one number');
    }
    if (textErr.length > 0) {
      return callback(`Password must contain ${textErr.join(', ')}`);
    }
    // if (value && value !== form.getFieldValue('password')) {
    //     callback('Two passwords that you enter is inconsistent!');
    // } else {
    //     callback();
    // }
    return callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">{/*<img src='https://via.placeholder.com/272x395' alt='Neature'/>*/}</div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signUp" />
                </h1>
                <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require('assets/images/logo-h.png')} />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Please input your First Name!' }]
                  })(<Input placeholder="Firstname" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Please input your Last Name!' }]
                  })(<Input placeholder="Lastname" />)}
                </FormItem>
                {/* <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{required: true, message: 'Please input your username!'}],
                  })(
                    <Input placeholder="Username"/>
                  )}
                </FormItem> */}
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem extra={<div className="text-gray text-small small">Passwords should contain at least one Uppercase letters A-Z, one Lowercase letters a-z and one Numbers 0-9</div>}>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Please input your Password!' },
                      // { min: 6, message: 'Password must be minimum 6 characters.' },
                      {
                        validator: this.strongPasswordCheck
                      }
                    ]
                  })(
                    <Input
                      type={this.state.showPlainPassword ? 'text' : 'password'}
                      placeholder="Password"
                      suffix={
                        <Icon
                          type="eye"
                          onClick={(e) => {
                            this.setState({
                              showPlainPassword: !this.state.showPlainPassword
                            });
                          }}
                        />
                      }
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('repassword', {
                    rules: [
                      { required: true, message: 'Please input your Password Again!' },
                      {
                        validator: this.comparePasswordCheck
                      }
                    ]
                  })(<Input type="password" placeholder="Confirm Password" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.iAcceptSignUp" />
                    </Checkbox>
                  )}
                  <a href="https://dikauri.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank">
                    <span className="gx-signup-form-forgot gx-link">
                      <IntlMessages id="appModule.termAndCondition" />
                    </span>
                  </a>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  <span className="mr-2">
                    <IntlMessages id="app.userAuth.or" />
                  </span>
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </FormItem>
              </Form>
              {/* or Sign up using: */}
              {/* <SocialLogin /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { userSignUp, socialLogin })(WrappedSignUpForm);
