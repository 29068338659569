import axios from "util/Api";

export const getListUser = params => {
  return axios.post("api/users/search", params).then(r => r.data);
};

export const getDetail = async params => {
  params.objectId = params.objectId || params.id;
  params.id = params.id || params.objectId;
  return axios.get("api/users/detail", params).then(r => r.data);
};

export const getLoggedUserInfo = async params => {
  return getDetail(params || {});
}

export const updatePasswordAuthUser = params => {
  return axios.post("api/users/change-password", { ...params, objectId: params.id }).then(r => r.data);
};

export const forgetPasswordUser = params => {
  return axios.post("/api/users/forget-password", { ...params }).then(r => r.data);
};

export const verify = params => {
  return axios.post("api/users/verify", params).then(r => r.data);
};

export const verifyUser = (params)=>{
  return verify(params);
}

export const resendEmail = params => {
  return axios.post("api/users/resendEmail", params).then(r => r.data);
};

export const updateStatus = params => {
  return axios.post("api/users/update-status", params).then(r => r.data);
};

export const updateType = params => {
  return axios.post("api/users/update-type", params).then(r => r.data);
};

export const update = params => {
  return axios.post("api/users/update", params).then(r => r.data);
};

export const registerNewUser = params => {
  return axios.post("api/users/register", params).then(r => r.data);
};

export const usersCloverLogin = params => {
  // {
  //   "merchantId": "{merchantId}",
  //   "appId": "{appId}",
  //   "authorizationCode": "{AUTHORIZATION_CODE}"
  // }
  return axios.post("api/users/clover-login", params).then(r => r.data);
};

export const updateUserInfo = update;

export const getActivity = params => {
  return axios.post("api/activity/user", params).then(r => r.data);
};

export const getAuthUser = () => {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (e) {
    return null;
  }
};
